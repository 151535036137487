import { VALEDICTORIAN_OPTIONS } from "../constants";

const getDataMailCert = (
  name,
  course,
  isValedictorian,
  valedictorianType = "1",
  classType
) => {
  console.log('classType', classType);
  return `
 <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>[THALIC VOICE] THƯ XÁC NHẬN HOÀN THÀNH KHOÁ HỌC - CHỨNG NHẬN, HỌC BỔNG</title>
    <style>
      .mail-wrapper {
        margin: 0 auto;
        max-width: 600px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        direction: ltr;
      }
      .mail-wrapper p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
      }
    </style>
  </head>
  <body>
    <div class="mail-wrapper">
      <div class="mail-banner">
        <img
          src="https://res.cloudinary.com/dinntgpwi/image/upload/v1713714775/thalic/ufkt9zx9ckjxqq3nwoqb.png"
          alt="Thalic Voice"
          style="width: 100%"
        />
      </div>
      <div class="mail-content">
        <br />
        <p>
          <i>Kính gửi quý học viên: ${name},</i>
        </p>
        <p>
          Chúc mừng bạn đã hoàn thành và tốt nghiệp khóa học
          <i>${course}</i> tại
          <b>Học viện giọng nói và kỹ năng THALIC VOICE.</b>
        </p>
        <p>THALIC VOICE gửi bạn các nội dung sau:</p>
        <p><b>- Chứng nhận:</b> Hoàn thành khóa học</p>
        ${
          isValedictorian
            ? `
        <p>
          <b>- Chứng nhận:</b> ${
            VALEDICTORIAN_OPTIONS.find(
              (item) => item.value === valedictorianType
            )?.title
          }
        </p>
        `
            : ""
        }
        ${
          classType === "ONLINE"
            ? `<p><b>- Học bổng quà tặng 500.000 VNĐ</b></p>`
            : ""
        }
        <p>
          <b>- Gửi lời cảm ơn, góp ý cho THALIC:</b>&nbsp;
          <a
            href="https://forms.gle/KRDGjfyYF9eu2zVdA"
            target="_blank"
            rel="noreferrer"
            >https://forms.gle/KRDGjfyYF9eu2zVdA</a
          >
        </p>
        <p>
          <b>- Group cựu học viên và cơ hội nghề nghiệp sau khoá học:</b>&nbsp;
          <a
            href="https://www.facebook.com/groups/clbthalicvoice"
            target="_blank"
            rel="noreferrer"
            >https://www.facebook.com/groups/clbthalicvoice</a
          >
        </p>
        <p><b>- Thư cảm ơn từ Đại diện THALIC VOICE</b></p>
        <p>
          Học viện THALIC VOICE và đội ngũ Giáo viên, Chuyên viên chuyên môn ghi
          nhận sự cố gắng và nỗ lực của bạn trong suốt khóa học. Đồng thời cũng
          nhìn thấy được sự tiến bộ, thay đổi tích cực từ bạn. Đó là động lực và
          niềm tự hào to lớn của chúng tôi.
        </p>
        <p>
          Mặc dù khóa học đã kết thúc nhưng mong rằng bạn vẫn chăm chỉ luyện tập
          hằng ngày, áp dụng kiến thức vào cuộc sống để có một giọng nói đẹp
          hơn, cuốn hút hơn, trau dồi thêm các kỹ năng - tư duy ngôn từ, giao
          tiếp, thuyết trình, nói trước đám đông nhé.
        </p>
        <p>
          Cảm ơn bạn đã tham gia khóa học và trở thành một phần của THALIC
          VOICE. Chúng ta đã đi với nhau một thời gian không quá dài nhưng đủ để
          mỗi lần bạn gặp khó khăn hay cần giúp đỡ, đừng ngần ngại chia sẻ với
          chúng tôi.
        </p>
        <p>
          Và để tham khảo nội dung kiến thức khác của THALIC VOICE, bạn vui lòng
          xem thông tin chi tiết tại:
        </p>
        <p>
          - Website:&nbsp;
          <a href="thalic.edu.vn" target="_blank" rel="noreferrer"
            >thalic.edu.vn</a
          >
        </p>
        <p>
          - Social media:&nbsp;
          <a
            href="https://www.tiktok.com/@thalicvoice"
            target="_blank"
            rel="noreferrer"
            >TikTok</a
          >,&nbsp;
          <a
            href="https://www.facebook.com/thalicvoice.hocviengiongnoivakynang"
            target="_blank"
            rel="noreferrer"
            >Facebook</a
          >,&nbsp;
          <a
            href="https://www.youtube.com/@thalicvoice"
            target="_blank"
            rel="noreferrer"
            >Youtube</a
          >,&nbsp;
          <a
            href="https://www.instagram.com/thalicvoice_hocviengiongnoi"
            target="_blank"
            rel="noreferrer"
            >Instagram</a
          >
        </p>
        <p>
          Chúc bạn và gia đình bình an, mạnh khỏe, thành công trong cuộc sống và
          công việc!
        </p>
        <p style="background-color: yellow">
          Nếu nhận được email này, <b>vui lòng phản hồi</b> để chúng tôi hoàn
          thành thủ tục kết thúc khoá học cho Giáo viên, Chuyên viên chuyên môn
          bạn nhé.
        </p>
        <p>
          Một lần nữa gửi đến bạn sự biết ơn sâu sắc và chân thành nhất cùng
          chúng tôi hoàn thành sứ mệnh
          <b><i>“Người Việt nói hay tiếng Việt”.</i></b>
        </p>
        <p>Thay mặt đội ngũ THALIC VOICE,</p>
        <p>
          <b><i>Nguyễn Hà Duy</i></b>
        </p>
        <p>
          <b><i>Giám đốc điều hành - Nhà đồng sáng lập THALIC VOICE</i></b>
        </p>
      </div>
    </div>
  </body>
</html>
  `;
};

export default getDataMailCert;

const getDataMailBill = (name, course, schedule, hour, open) => {
  return `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>[THALIC VOICE] THƯ XÁC NHẬN ĐĂNG KÝ KHOÁ HỌC</title>
      <style>
        .mail-wrapper {
          margin: 0 auto;
          max-width: 600px;
          text-align: justify;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 15px;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          direction: ltr;
        }
        .mail-container {
          margin: 0;
          padding: 0;
        }
        .mail-wrapper p {
          display: block;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          unicode-bidi: isolate;
        }
        .mail-wrapper table {
          width: 100%;
          table-layout: fixed;
          border: 1px solid black;
          border-collapse: collapse;
          text-align: center;
        }
        .mail-wrapper table th,
        .mail-wrapper table td {
          padding: 8px;
          border: 1px solid black;
          border-collapse: collapse;
          text-align: center;
        }
      </style>
    </head>
    <body>
      <div class="mail-wrapper">
        <div class="mail-container">
          <div class="mail-banner">
            <img
              src="https://res.cloudinary.com/dinntgpwi/image/upload/v1713714775/thalic/ufkt9zx9ckjxqq3nwoqb.png"
              alt="Thalic Voice"
              style="width: 100%"
            />
          </div>
          <div class="mail-content">
            <br />
            <p><i>Kính gửi học viên: ${name},</i></p>
            <p>
              <b>Học viện Giọng nói và Kỹ năng THALIC VOICE</b> chân thành cảm ơn
              bạn vì đã tin tưởng và lựa chọn THALIC VOICE để đồng hành cùng bạn
              trên con đường cải thiện
              <b>Giọng nói, Kỹ năng và phát triển bản thân!</b>
            </p>
            <p>
              THALIC VOICE xin thông báo thông tin khoá học mà bạn đăng ký tham
              gia như sau:
            </p>
            <table role="grid">
              <thead>
                <th colspan="2">
                  <b style="text-transform: uppercase; font-family: Arial, Helvetica, system-ui, sans-serif;">KHÓA HỌC: ${course} </b>
                </th>
              </thead>
              <tbody>
                <tr roll="gridcell">
                  <td>Lịch học</td>
                  <td>Ngày khai giảng</td>
                </tr>
                <tr roll="gridcell">
                  <td>
                    <b>${schedule}</b>
                    <br />
                    <b>${hour && `${hour}`}</b>
                  </td>
                  <td>
                    <b>${open}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <p>
              Thông tin chi tiết phiếu thu, thư mời ở
              <b><u>file đính kèm</u></b> bên dưới:
            </p>
            <p>
              <i
                >* Thông tin học cụ thể, link nhóm lớp, tài liệu học và các thông
                tin cần thiết khác chúng tôi sẽ tiếp tục cập nhật đến bạn trong
                thời gian trước ngày khai giảng qua kênh <b>Zalo.</b>
              </i>
            </p>
            <p>
              <i
                >* Nếu có bất kỳ thay đổi nào so với thông báo ban đầu, Tư vấn
                tuyển sinh sẽ thông tin đến bạn thông qua hình thức trao đổi trước
                đó.
              </i>
            </p>
            <p>
              <i
                >* THALIC không hỗ trợ hoàn học phí cho quý học viên đã đăng ký.
              </i>
            </p>
            <p>
              <b><u>THÔNG TIN HỖ TRỢ:</u></b>
            </p>
            <p style="text-align: left; word-break: break-all;">
              <b>- VIDEO BỔ SUNG KIẾN THỨC KHOÁ HỌC: </b
              ><a
                href="https://www.youtube.com/@thalicvoice/videos"
                target="_blank"
                rel="noreferrer"
                >https://www.youtube.com/@thalicvoice/videos</a
              >
            </p>
            <p style="text-align: left; word-break: break-all;">
              <b>- KÊNH THÔNG TIN CHÍNH THỨC: </b
              ><a href="https://thalic.edu.vn/" target="_blank" rel="noreferrer"
                >https://thalic.edu.vn/</a
              >
            </p>
            <p style="text-align: left; word-break: break-all;">
              <b>- CÁC KÊNH SOCIAL MEDIA: </b
              ><a
                href="https://www.tiktok.com/@thalicvoice"
                target="_blank"
                rel="noreferrer"
                >TikTok</a
              >,&nbsp;
              <a
                href="https://www.facebook.com/thalicvoice.hocviengiongnoivakynang"
                target="_blank"
                rel="noreferrer"
                >Facebook</a
              >,&nbsp;
              <a
                href="https://www.youtube.com/@thalicvoice"
                target="_blank"
                rel="noreferrer"
                >Youtube</a
              >,&nbsp;
              <a
                href="https://www.instagram.com/thalicvoice_hocviengiongnoi"
                target="_blank"
                rel="noreferrer"
                >Instagram</a
              >
            </p>
            <p style="background: #ffcd0f;">
              <b><u>LƯU Ý: </u></b>Khi nhận được email này,
              <b>vui lòng phản hồi và xác nhận thông tin</b> để THALIC hoàn thành
              các thủ tục nhập học cho bạn.
            </p>
            <p>
              Xin chân thành cảm ơn và chúc quý học viên có một khoá học bổ ích,
              hiệu quả.
            </p>
            <p>Trân trọng,</p>
            <p><b>Mr. Nguyễn Hà Duy</b></p>
            <p><i>CEO & Co-founder THALIC VOICE</i></p>
          </div>
        </div>
      </div>
    </body>
  </html>
  `;
};

export default getDataMailBill;
